@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Font
.ngx-table {
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #ffffff;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #ff4e44;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #ffffff;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

$table-border-color: #e3e6f0;
$table-border-width: 1px;

.scrollable {
  white-space: nowrap;
  overflow-x: scroll;

  .table {
    border-collapse: separate;
    border-spacing: 0;
    border-right: $table-border-width solid $table-border-color;
    border-bottom: $table-border-width solid $table-border-color;
    margin-bottom: 0;

    td, th {
      border-left: $table-border-width solid $table-border-color;
      border-top: $table-border-width solid $table-border-color;
    }
  }

  .row-header {
    border-right: 2*$table-border-width solid $table-border-color !important;
    position: sticky;
    left: 0;
    top: auto;
    z-index: 50;
    background-color: inherit;
  }
}

.table tr {
  background-color: white;

  &.odd-row {
    background-color: #f7f7f7;
  }
}

.table.data-table {

  td, th {
    text-align: right;
    padding: 0.25rem;
    font-size: 14px;
  }
}

thead th {
  border-bottom: none!important;
  border-top: none!important;
}

thead > tr:first-child th {
  border-top: $table-border-width solid $table-border-color !important;
}

thead > tr:last-child th {
  border-bottom: 2*$table-border-width solid $table-border-color !important;
}

.form-check:not(.loading) {
  .form-check-input, .form-check-label {
    cursor: pointer;
  }
}

.btn-success:focus {
  background-color: #1cc88a;
  border-color: #1cc88a;
}

.btn-danger, .btn-warning {
  color: #fff !important;
}

input, select{
  &.ng-touched{
    &.ng-invalid {
      border-right: 5px solid red;
    }
  }
}

.warning-icon {
  font-size: 3em;
  margin-right: 1rem;
}

.clickable:hover {
  cursor: pointer;
  filter: brightness(80%);
}

.table-selectable tr:hover {
  cursor: pointer;
}

$btn-eye-color: #29b1b6;
.btn-eye {
  background-color: $btn-eye-color !important;
  border-color: $btn-eye-color !important;
  color: white!important;

  &:hover {
    background-color: darken($btn-eye-color, 5%)!important;
    border-color: darken($btn-eye-color, 5%)!important;
  }
}

.modal {
  z-index: 1056!important;
}

.card-body {
  overflow-x: visible!important;
}

.table-responsive {
  overflow-x: visible!important;
}

.fit-content {
  height: fit-content;
}

.badge.badge-indigo {
  background-color: $indigo;
}
